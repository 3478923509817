
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import PopUp from "@/components/PopUp.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import utils from "@/utils/utils";

@Component({
  components: { Header, Loader, PopUp, FilterComponent },
})
export default class UserManagement extends Vue {
  public currentPage = 1;
  loading = true;
  public selected_groups: any = [];
  openPermission = false;
  permission = [];
  public groupObject: any = {};
  saveData = true;
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  formatPermissions(permissionArray: any) {
    return permissionArray.join(', ');
  }
  togglePermissionDropdown() {
    this.openPermission = !this.openPermission;
    this.getPermission();
  }
  public permission_options: any = [];
  getPermission() {
    this.loading = true;
    UserManagementService.get_screen_permission()
      .then((res) => {
        this.permission_options = res.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  retrieve() {
    this.loading = true;
    UserManagementService.get_groups()
      .then((res) => {
        this.selected_groups = res.data.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  get rows() {
    return this.selected_groups.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.selected_groups.slice(start, end);
  }
  mounted() {
    this.retrieve();
    this.getPermission();
  }
  saveGroup() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.groupObject, ['title'])
    if (error_list.length > 0 || this.permission === null || this.permission.length === 0 || this.permission === undefined) {
      this.error = utils.generateErrorMessage(error_list.concat(this.permission === null || this.permission.length === 0 || this.permission === undefined ? 'permission' : []));
      // if (error_list.length > 0) {
      //   this.error = utils.generateErrorMessage(error_list)
    }
    else {
      // if (!this.groupObject.title) {
      //   this.errorMessages.push("Please enter the Title.");
      // }
      // if (this.permission.length === 0) {
      //   this.errorMessages.push("Please select the permission.");
      // }
      // if (this.errorMessages.length > 0) {
      //   this.showModalErrors(this.errorMessages, "errorArray");
      // }
      const permission_id: any = this.permission
      const selectedPermissionId = permission_id.map((permission: any) => {
        const option = this.permission_options.find((option: any) => option.title === permission)
        return option?.id;
      })
      const groupObject = {
        title: this.groupObject.title,
        permissions: selectedPermissionId,
      };
      // if (this.errorMessages.length === 0) {
      this.loading = true;
      UserManagementService.post_group_permissoin(groupObject)
        .then((res) => {
          this.resetGroup();
          this.retrieve();
          this.refreshFilters();
          this.showModal("Created Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  editGroupToggle(data: any) {
    this.saveData = false;
    this.groupObject = data;
    this.permission = this.groupObject.permission_title.slice();
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-group");
  }
  editGroup() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.groupObject, ['title'])
    if (error_list.length > 0 || this.permission === null || this.permission.length === 0 || this.permission === undefined) {
      this.error = utils.generateErrorMessage(error_list.concat(this.permission === null || this.permission.length === 0 || this.permission === undefined ? 'permission' : []));
    }
    else {
      const permission_id: any = this.permission
      const selectedPermissionId = permission_id.map((permission: any) => {
        const option = this.permission_options.find((option: any) => option.title === permission)
        return option?.id;
      })
      this.groupObject.permissions = selectedPermissionId;
      this.loading = true;
      UserManagementService.update_group_permission(this.groupObject.id, this.groupObject)
        .then((res) => {
          this.resetGroup()
          this.retrieve();
          this.refreshFilters();
          this.showModal("Updated Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  deleteGroup(item: any) {
    this.loading = true;
    UserManagementService.delete_group(item.id)
      .then((res) => {
        this.retrieve();
        this.refreshFilters();
        this.showModal("Deleted Successfully", [], "success");
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  resetGroup() {
    this.error = "";
    this.saveData = true;
    this.groupObject = {
      groupTitle: '',
    }
    this.permission = [];
    for (let field of this.filter_fields) {
      field.value = "";
    }
  }
  filter_fields = [
    { type: "text", label: "Title", name: "title", value: "", param_name: "title", filter_type: "simple", },
    { type: "text", label: "Permission", name: "permission", value: "", param_name: "permission_title", filter_type: "simple", },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.get_groups(simpleFilteredObj).then((data) => {
        if (
          data.data.data !== undefined &&
          data.data.data !== null
        ) {
          this.selected_groups = data.data.data;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  refreshFilters() {
    this.resetGroup();
  }
}
